import classes from "./Overview.module.css";
import OverviewDetails from "./Overview/OverviewDetails";
import OverviewImage from "./Overview/OverviewImage";
import React from 'react';

const Overview = (props) => {
  return (
    <div ref={props.overRef} className={classes.container}>
      <OverviewDetails inverse={props.inverse}/>
      <OverviewImage inverse={props.inverse}/>
    </div>
  );
};

export default Overview;
