import classes from "./Screenshot.module.css";
import React from 'react';

const Screenshot = () => {
  return (
    <div className={classes.container}>
      <img className={classes.leftImage} src={"screen1.PNG"} alt="" />
      <img className={classes.rightImage} src={"screen2.PNG"} alt="" />
    </div>
  );
};

export default Screenshot;
