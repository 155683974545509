import classes from "./OverviewImage.module.css";
import React from 'react';

const OverviewImage = (props) => {
  return (
    <div className={props.inverse ? classes.containerInverse : classes.container}>
      <img className={classes.image} src={props.inverse ? "overview2.png" : "overview1.png"} alt="" />
    </div>
  );
};

export default OverviewImage;