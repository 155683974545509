import Main from "./Components/Main";
import Header from "./Components/Header/Header";
import Overview from "./Components/Overview";
import { useRef } from "react";
import ReactGA from "react-ga4";
import React from 'react';

ReactGA.initialize('G-KL1X6VN3F6');




function App() {


  const overviewRef = useRef()

  const executeScroll = () => {
    overviewRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div>
      <Header scrollOverview= {executeScroll} />
      <Main />
      <Overview overRef={overviewRef} inverse={false}/>
      <Overview inverse={true}/>
    </div>
  );
}

export default App;
